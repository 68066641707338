import { useState, useRef } from 'react';
import {
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  Card,
  CardContent,
  ClickAwayListener,
  Grow,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  Stack,
  useTheme,
} from '@mui/material';
import { Skeleton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRotateRight,
  faEllipsisV,
  faLock,
  faLockOpen,
  faTrash,
  faCircleUser,
} from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import { components } from 'openapi-types';
import {
  useDeleteUser,
  useGetAccountUsers,
  useReinviteUser,
  useUserChangeTwoAuth,
} from 'api/users';
import { ConfirmationDialog } from 'component/base/ConfirmDialog';
import { ChangeUserRoleDialog } from './ChangeUserRoleDialog';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { TableHeadCell } from 'component/base/TableHeadCell';
import PaginationControls from 'component/base/PaginationControls';

export default function RowSkeleton() {
  const rows = () => {
    return Array.from({ length: 3 }).map((v, i) => (
      <TableRow key={i}>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    ));
  };

  return <>{rows()}</>;
}

type User = NonNullable<components['schemas']['UserList']['result']>[0];

type UserRowProps = { readonly user: User };

function UserRow({ user }: UserRowProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [openTableMenu, setOpenTableMenu] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [openRoleDialog, setOpenRoleDialog] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const { mutateAsync: deleteUser } = useDeleteUser();
  const { mutateAsync: changeTwoFactorAuth } = useUserChangeTwoAuth();
  const { mutateAsync: reinviteUser } = useReinviteUser();

  return (
    <TableRow key={user.id}>
      <TableCell>
        {user.firstname} {user.lastname}
      </TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>{user.twofactor_enabled ? t('enabled') : t('disabled')}</TableCell>
      <TableCell>{user.twofactor_required ? t('yes') : t('no')}</TableCell>
      <TableCell>{t(user.status?.toString() ?? '')}</TableCell>
      <TableCell>{user.roles?.map(role => t(role)).join(', ')}</TableCell>
      <TableCell
        sx={{
          textAlign: 'center',
        }}
      >
        <Button
          ref={anchorRef}
          variant="text"
          color="primary"
          sx={{
            minHeight: '2.75rem',
          }}
          fullWidth
          onClick={async () => {
            setOpenTableMenu(true);
          }}
        >
          <FontAwesomeIcon size="lg" icon={faEllipsisV} onClick={() => {}} />
          <ConfirmationDialog
            action="delete"
            onConfirm={async () => {
              deleteUser(
                { userId: user.id ?? '' },
                {
                  onSuccess: () => {
                    setOpenConfirmationDialog(false);
                  },
                }
              );
            }}
            description={t('delete_description', {
              name: `${user.firstname} ${user.lastname}`,
            })}
            onClose={() => setOpenConfirmationDialog(false)}
            open={openConfirmationDialog}
            title={t('delete_user', {
              name: `${user.firstname} ${user.lastname}`,
            })}
          />
          {openRoleDialog ? (
            <ChangeUserRoleDialog
              user={user}
              onClose={() => {
                setOpenRoleDialog(false);
              }}
            />
          ) : null}
          <Popper
            popperOptions={{
              placement: 'bottom-start',
            }}
            open={openTableMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={() => setOpenTableMenu(false)}>
                    <MenuList
                      autoFocusItem={openTableMenu}
                      id="menu-list-grow"
                      onKeyDown={() => {}}
                    >
                      {user.status === 'invited' ? (
                        <MenuItem
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            reinviteUser({
                              userId: user.id ?? '',
                            });
                            setOpenTableMenu(false);
                          }}
                        >
                          <Stack spacing={1} direction="row" alignItems="center">
                            <FontAwesomeIcon
                              color={theme.palette.primary.main}
                              icon={faArrowRotateRight}
                            />
                            <Box>{t('resend_invitation')}</Box>
                          </Stack>
                        </MenuItem>
                      ) : null}
                      {!user.twofactor_required && (
                        <MenuItem
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            changeTwoFactorAuth({ userId: user.id ?? '', twofa: true });
                            setOpenTableMenu(false);
                          }}
                        >
                          <Stack spacing={1} direction="row" alignItems="center">
                            <FontAwesomeIcon color={theme.palette.primary.main} icon={faLock} />{' '}
                            <Box>{t('force_2factor_auth')}</Box>
                          </Stack>
                        </MenuItem>
                      )}
                      {user.twofactor_required === true && (
                        <MenuItem
                          onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            changeTwoFactorAuth({ userId: user.id ?? '', twofa: false });
                            setOpenTableMenu(false);
                          }}
                        >
                          <Stack spacing={1} direction="row" alignItems="center">
                            <FontAwesomeIcon color={theme.palette.primary.main} icon={faLockOpen} />{' '}
                            <Box>{t('force_remove_2factor_auth')}</Box>
                          </Stack>
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault();
                          setOpenTableMenu(false);
                          setOpenRoleDialog(true);
                        }}
                        disabled={user.roles?.includes('account_owner')}
                      >
                        <Stack spacing={1} direction="row" alignItems="center">
                          <FontAwesomeIcon color={theme.palette.primary.main} icon={faCircleUser} />{' '}
                          <Box>{t('change_role')}</Box>
                        </Stack>
                      </MenuItem>
                      <MenuItem
                        onClick={e => {
                          e.stopPropagation();
                          e.preventDefault();
                          setOpenTableMenu(false);
                          setOpenConfirmationDialog(true);
                        }}
                      >
                        <Stack spacing={1} direction="row" alignItems="center">
                          <FontAwesomeIcon color={theme.palette.primary.main} icon={faTrash} />{' '}
                          <Box>{t('security_delete_user')}</Box>
                        </Stack>
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Button>
      </TableCell>
    </TableRow>
  );
}

export function UsersList() {
  const { t } = useTranslation();
  const tableState = usePaginatedListState();
  const { data, status, isLoading } = useGetAccountUsers(tableState);

  const users = data?.data.result ?? [];

  const renderTableRows = () => {
    if (status === 'pending') {
      return <RowSkeleton />;
    }

    return users.map(user => {
      if (!user) {
        return null;
      }
      return <UserRow key={user?.id ?? ''} user={user} />;
    });
  };

  return (
    <Card sx={{ marginTop: '0px !important' }}>
      <CardContent>
        <TableContainer>
          <Table aria-label="Users List Table">
            <TableHead>
              <TableRow>
                <TableHeadCell tableState={tableState} column="name" label={t('name')} />
                <TableCell>{t('email')}</TableCell>
                <TableHeadCell
                  tableState={tableState}
                  column="status_2fa"
                  label={t('2fa_authentication')}
                />
                <TableCell>{t('2fa_required')}</TableCell>
                <TableCell>{t('status')}</TableCell>
                <TableCell>{t('roles')}</TableCell>
                <TableCell width={70}>{t('action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableRows()}</TableBody>
          </Table>
        </TableContainer>
        {!isLoading && (
          <PaginationControls
            page={tableState.params.page}
            totalRowCount={data?.data.metadata?.total || 0}
            perPage={tableState.params.perPage}
            onPageChange={page => tableState.setPage(page)}
            onPerPageChange={perPage => tableState.setPerPage(perPage)}
          />
        )}
      </CardContent>
    </Card>
  );
}
