import { Box, Container, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UsersList } from './UsersList';
import InviteUser from './InviteUser';

export function AccountUsers() {
  const { t } = useTranslation();

  return (
    <main id="mainContent">
      <Container maxWidth="lg">
        <Stack direction="row" justifyContent="space-between">
          <Box marginBottom={3}>
            <Typography variant="h1" data-testid="domainLabel">
              {t('account_users')}
            </Typography>
            <Typography>{t('account_users_description')}</Typography>
          </Box>
          <InviteUser />
        </Stack>

        <UsersList />
      </Container>
    </main>
  );
}
